<template>
  <div id="innerApp" v-if="ready">
    <Header></Header>
    <main :class="{ 'noHeader': isMobile && $route.meta.noHeader}">
      <transition name="fade">
        <router-view v-if="animateReady"/>
      </transition>
      <create-outfit v-if="create && canCreate && !isMobile" @createOutfitError="toggleToastr"></create-outfit>
      <create-outfit-mobile v-if="create && canCreate && isMobile" @createOutfitError="toggleToastr"></create-outfit-mobile>
    </main>
    <Footer v-if="$route.name === 'Profile'"></Footer>
    <transition name="fade">
      <AddAppToHomeScreen v-if="!addApp"/>
    </transition>
    <transition name="fade">
      <toastr v-if="toastr.show" :content="toastr.content" @toggleToastr="toggleToastr"></toastr>
    </transition>
    <transition name="fade">
      <popup-white v-if="!canCreate" :content="popupBeforeUpload" @handlePopup="handleCanCreate"/>
      <notifications-popup v-if="$store.state.auth && $store.state.auth.token && $store.state.auth.token.token" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import userStore from '@/store/modules/user'
import outfitStore from '@/store/modules/outfit'
import settingsStore from '@/store/modules/settings'
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'
import CreateOutfit from '@/components/outfit/CreateOutfit'
import PopupWhite from '@/components/modals/PopupWhite'
import NotificationsPopup from '@/components/modals/NotificationsPopup'
import AddAppToHomeScreen from '@/components/modals/AddAppToHomeScreen'
import Toastr from '@/components/modals/Toastr'

const CreateOutfitMobile = () => import(/* webpackChunkName: "CreateMobileOutfit" */ '@/components/outfit/CreateOutfitMobile')
const smallDevice = window.matchMedia('(max-width: 767px)')

export default {
  mixins: [
    dynamicModule('user', userStore),
    dynamicModule('outfit', outfitStore),
    dynamicModule('settings', settingsStore)
  ],
  components: {
    NotificationsPopup,
    Header,
    Footer,
    CreateOutfit,
    CreateOutfitMobile,
    AddAppToHomeScreen,
    PopupWhite,
    Toastr
  },
  data () {
    return {
      popupBeforeUpload: {
        title: this.__t('rate-before-post-popup.title'),
        text: this.__t('rate-before-post-popup.description'),
        buttons: {
          text: this.__t('rate-before-post-popup.lets-do-it')
        }
      },
      animateReady: true,
      ready: false,
      toastr: {
        show: false,
        content: null
      }
    }
  },
  watch: {
    $route (to, from) {
      this.create && this.toggleCreate(false)
      this.animateReady = false
      this.$nextTick(() => {
        this.animateReady = true
      })
    }
  },
  computed: {
    ...mapState('auth', ['token', 'addApp']),
    ...mapState('outfit', ['create', 'canCreate']),
    isMobile () {
      return smallDevice.matches
    }
  },
  methods: {
    ...mapActions('auth', ['searchBase', 'saveToken']),
    ...mapActions('outfit', ['toggleCreate', 'checkCanCreate']),
    handleCanCreate (val) {
      this.checkCanCreate(false)
      val && this.$route.name !== 'Home' && this.$router.push({ name: 'Home' })
    },
    handleViewResize () {
      let dBody = document.querySelector('body')
      dBody.style.height = window.outerHeight + 'px'
    },
    toggleToastr (data) {
      this.toastr.show = data.show
      this.toastr.content = data.content
      if (data.show) {
        setTimeout(() => {
          this.toastr.show = false
        }, 10000)
      }
    }
  },
  mounted () {
    const userAgent = window.navigator.userAgent

    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      window.addEventListener('resize', this.handleViewResize)
      this.handleViewResize()
    }
  },
  beforeCreate () {
    // this.$store.state.auth.token.token && this.$store.dispatch('auth/searchBase')
  },
  created () {
    !this.$store.state.auth.base?.baseFilePath && this.$store.state.auth?.token?.token ? this.searchBase().then(() => { this.ready = true }) : this.ready = true
    const userAgent = window.navigator.userAgent
    if (!(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i))) {
      // window.OneSignal.log.setLevel('trace')
      window.OneSignal = window.OneSignal || []
      window.OneSignal.push(() => {
        window.OneSignal.init({
          appId: process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ONESIGNAL : process.env.VUE_APP_ONESIGNAL_DEV,
          safari_web_id: 'web.com.wearitoutapp.wearitout',
          allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production'
        })

        window.OneSignal.getUserId((userId) => {
          if (userId == null) {
            window.OneSignal.showNativePrompt()
          } else {
            this.saveToken({ token: userId })
          }
        })

        window.OneSignal.on('subscriptionChange', (isSubscribed) => {
          isSubscribed && window.OneSignal.getUserId((userId) => {
            this.saveToken({ token: userId })
          })
        })
      })
    }
  },
  beforeDestroy () {
    const userAgent = window.navigator.userAgent

    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      window.removeEventListener('resize', this.handleViewResize)
    }
  }
}
</script>

<template>
    <div class="popup-white">
        <div class="popup-white-in">
          <div class="popup-white-in-content">
            <h2 v-html="content.title"></h2>
            <h4 class="h4-style" v-html="content.text"></h4>
            <div class="popup-white-in-buttons">
                <div class="yellow-button">
                    <a href="#" class="btn-default" @click.prevent="$emit('handlePopup', true)" v-text="content.buttons.text"></a>
                    <a href="#" class="not-now" @click.prevent="$emit('handlePopup', false)">NOT NOW!</a>
                </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {}
  }
}
</script>

import { Http } from '@/helpers/http-helper'
// import router from '@/router/index'

const state = {
  signInWithFacebook: false,
  signInWithInstagram: false,
  emails: false,
  pushNotifications: false,
  fromTheApp: false,
  lowDataUsage: false
}

const getters = {
  // getSettings: state => state
}

const mutations = {
  setSettings (state, data) {
    state = data
  },
  setSetting (state, data) {
    state[data.state] = data.data
  }
}

const actions = {
  searchSettings ({ commit }) {
    return Http.get('settings').then(resp => {
      commit('setSettings', resp.data)
      // for(let i; i <)
      return resp.data
    }).catch(err => Promise.reject(err))
  },
  updateSettings ({ commit, dispatch }, data) {
    return Http.post('settings/set', data).then(resp => {
      dispatch('searchSettings')
      return resp.data
    }).catch(err => Promise.reject(err))
  }
  // searchSetting ({ commit }, data) {
  //   return Http.get('settings/get', {
  //     params: {
  //       key: data
  //     }
  //   })
  // }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
